import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useToggle } from 'hooks/use-toggle'
import Button from 'components/button'
import H2L from 'components/typography/h2'
import formatCategoryTitle from 'utils/format-category-title'
import Accordion from 'components/accordion'
import SimilarProducts from 'components/similar-products'
import { Helmet } from 'react-helmet'
import { JohnDeereBrand, JohnDeereOrganization } from 'data/schema-data'
import Layout from 'components/layout'
import Content from 'components/content'
import { graphql } from 'gatsby'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import TopFold from 'components/products/top-fold'
import QuoteForm from 'components/forms/quote'
import Modal from 'components/modal/modal'
import ProductCarousel from 'components/product-carousel'
import { GatsbyImage } from 'gatsby-plugin-image'

const ImplementTemplate = props => {
  const {
    breadcrumbs,
    location,
    meta,
    product,
    titles,
    similar,
    images,
    resourceLinks,
    compatibleEquipment,
    salesBackgroundImage,
  } = getInitialProps(props)

  const [open, toggleModal] = useToggle(false)

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        'product-price': product.productPrice,
      })
    }
  }, [product.productPrice])
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta property='og:title' content={meta.title} />
        <meta property='og:description' content={meta.description} />
        <meta property='og:url' content={meta.pageUrl} />
        <meta property='og:image' content={product.image} />
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content='@Hutson_Inc' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Product',
            'description': meta.description,
            'name': product.name,
            'image': product.image,
            'sku': product.sku,
            'mpn': product.baseCode,
            'category': `Implements/${titles.category}/${titles.subcategory}/${titles.series}`,
            'brand': JohnDeereBrand,
            'manufacturer': JohnDeereOrganization,
          })}
        </script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Content>
        <TopFold
          title={product.name}
          overview={product.overview}
          images={images}
          toggleModal={toggleModal}
          breadcrumbs={breadcrumbs}
          resourceLinks={resourceLinks}
        />

        {product.features && product.features.length > 0 && (
          <Section>
            <Accordion data={product.features} type='features' />
          </Section>
        )}

        {product.specs && product.specs.length > 0 && (
          <Section>
            <Accordion data={product.specs} type='specs' />
          </Section>
        )}

        {compatibleEquipment && compatibleEquipment.length > 0 && (
          <Section>
            <H2L>Compatible Equipment</H2L>
            <ProductCarousel products={compatibleEquipment} />
          </Section>
        )}
      </Content>

      {similar && (
        <SimilarProductsContainer>
          <Content>
            <H2L>Similar Products</H2L>
            <SimilarProducts similarProducts={similar} />
          </Content>
        </SimilarProductsContainer>
      )}

      <SalesContainer>
        <SalesBackground>
          <GatsbyImage
            image={salesBackgroundImage.childImageSharp.gatsbyImageData}
            objectFit='cover'
            objectPosition='50% 50%'
            alt=''
            style={{ width: '100%', height: '100%' }}
          />
        </SalesBackground>
        <SalesContent>
          <h4>Our sales staff cares about finding the best unit for your needs</h4>
          <StyledButton kind='div' onClick={toggleModal}>
            Speak to Sales
          </StyledButton>
        </SalesContent>
      </SalesContainer>

      <Modal toggle={toggleModal} isOpen={open}>
        <QuoteForm
          toggleModal={toggleModal}
          productName={product.name}
          productPrice={product.productPrice}
          productType='implement'
          submitButtonClass='submit-aftermarket-quote'
          pageURL={location.href}
          equipmentBrand='John Deere'
          equipmentCondition='new'
        />
      </Modal>
    </Layout>
  )
}

const Section = styled.div`
  margin-top: 40px;

  @media (min-width: 800px) {
    margin-top: 80px;
  }
`

const SimilarProductsContainer = styled.div`
  background-color: #f2f0f0;
`

const SalesContainer = styled.div`
  height: auto;
  position: relative;
  width: 100%;
`

const SalesBackground = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
`

const SalesContent = styled(Content)`
  position: relative;
  z-index: 1;

  h4 {
    color: #fff;
    font-size: 1.5em;
    margin: 0 0 20px;
    max-width: 300px;
    text-align: left;
  }
`

const StyledButton = styled(Button)`
  margin: 0;
  max-width: 200px;
`

const fetchSimilarProducts = (all, productSku) => {
  let index = all.findIndex(obj => obj.sku === productSku)
  let similar = []

  for (let i = 1; i <= 3; i++) {
    let obj = all[index + i]
    if (obj) similar.push(obj)
  }

  for (let i = -1; i >= -3; i--) {
    let obj = all[index + i]
    if (obj) similar.push(obj)
  }

  return similar.splice(0, 3)
}

const getInitialProps = ({
  data: { allDeereProduct, allDeereImplement, deereImplement: product, salesBackgroundImage },
  location,
  pageContext: { category, subcategory, series },
}) => {
  allDeereImplement = allDeereImplement.edges.map(edge => edge.node)
  const titles = {
    category: formatCategoryTitle(category),
    subcategory: formatCategoryTitle(subcategory),
    series: formatCategoryTitle(series),
  }
  let similar
  if (allDeereImplement.length > 1) {
    similar = fetchSimilarProducts(allDeereImplement, product.sku)
    similar = similar.map(obj => ({
      id: obj.sku,
      image: obj.image,
      imageAlt: obj.imageAlt,
      link: `/implements/${category}/${subcategory}/${series}/${obj.sku}`,
      subtitle: `${titles.subcategory}/${titles.series}`,
      title: obj.name,
    }))
  }
  const compatibleEquipment = []
  if (allDeereProduct) {
    allDeereProduct = allDeereProduct.edges.map(edge => edge.node)
    compatibleEquipment.push(
      ...allDeereProduct.map(obj => {
        return {
          sku: obj.sku,
          slug: obj.slug,
          image: obj.image,
          imageAlt: obj.imageAlt,
          name: obj.name,
        }
      })
    )
  }
  const meta = {
    title: `${titles.series} - ${product.name} | Hutson Inc`,
    description: product.description || `Learn more about ${titles.series} at Hutson.`,
    pageUrl: `https://www.hutsoninc.com/implements/${category}/${subcategory}/${series}/${product.sku}/`,
  }
  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: 'Implements',
      link: '/implements/',
    },
    {
      name: titles.category,
      link: `/implements/${category}/`,
    },
    {
      name: titles.subcategory,
      link: `/implements/${category}/${subcategory}/`,
    },
    {
      name: titles.series,
      link: `/implements/${category}/${subcategory}/${series}/`,
    },
    {
      name: product.name,
      link: `/implements/${category}/${subcategory}/${series}/${product.sku}/`,
    },
  ]
  const images = [
    {
      original: product.image,
      thumbnail: product.image,
      originalAlt: product.imageAlt,
      thumbnailAlt: `Photo 1 of 1`,
    },
  ]
  const resourceLinks = []
  if (product.productBrochure) {
    resourceLinks.push(
      <a
        href={product.productBrochure}
        target='_blank'
        rel='noopener noreferrer'
        id='request-brochure'
        key='product-brochure'
      >
        View product brochure
      </a>
    )
  }
  return {
    breadcrumbs,
    category,
    compatibleEquipment,
    images,
    location,
    meta,
    product,
    resourceLinks,
    salesBackgroundImage,
    series,
    similar,
    subcategory,
    titles,
  }
}

export const pageQuery = graphql`
  query implementQuery(
    $id: String!
    $series: String!
    $subcategory: String!
    $compatibleEquipment: [String!]
  ) {
    deereImplement(sku: { eq: $id }) {
      name
      sku
      image
      imageAlt
      overview
      productPrice
      specs {
        name
        specs {
          description
          name
        }
      }
      features {
        title
        content
      }
      description
      keywords
      baseCode
      productBrochure
      compatibility {
        skus
        data {
          sku
          version
        }
      }
    }
    allDeereImplement(
      filter: { series: { eq: $series }, subcategory: { eq: $subcategory } }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          sku
          name
          image
          imageAlt
        }
      }
    }
    allDeereProduct(
      filter: { sku: { in: $compatibleEquipment } }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          image
          imageAlt
          name
          overview
          sku
          slug
        }
      }
    }
    salesBackgroundImage: file(relativePath: { eq: "speak-to-sales-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default ImplementTemplate
